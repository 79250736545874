// import RestModel from 'discourse/models/rest';

// export default RestModel.extend({

//   updateProperties() {
//     return this.getProperties('date', 'externalId','status');
//   }
// });
define("discourse/plugins/migrate-users/discourse/models/userlog", [], function () {
  "use strict";
});