define("discourse/plugins/migrate-users/discourse/controllers/admin-plugins-migrate-users", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminPLuginsMigrateUsersController = _exports.default = (_class = class AdminPLuginsMigrateUsersController extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "userCsv", _descriptor, this);
      _initializerDefineProperty(this, "groupCsv", _descriptor2, this);
      _initializerDefineProperty(this, "userLogs", _descriptor3, this);
      _initializerDefineProperty(this, "serverReceivedUsers", _descriptor4, this);
      _initializerDefineProperty(this, "serverDestroyedLogs", _descriptor5, this);
      _initializerDefineProperty(this, "errorMessage", _descriptor6, this);
      _initializerDefineProperty(this, "filesUploaded", _descriptor7, this);
      _initializerDefineProperty(this, "allowUpload", _descriptor8, this);
    }
    getUserLogs() {
      this.serverDestroyedLogs = false;
      fetch("/userlogs.json").then(res => res.json()).then(json => {
        this.userLogs = json.userlogs;
      });
    }
    destroyLogs() {
      this.serverDestroyedLogs = false;
      this.serverReceivedUsers = false;
      fetch('/userlogs/destroy.json').then(res => res.json()).then(json => {
        if (json.status == 200) {
          this.serverDestroyedLogs = true;
          this.userLogs = "";
        }
      });
    }
    uploadUsersFile(event) {
      this.serverDestroyedLogs = false;
      this.serverReceivedUsers = false;
      this.errorMessage = "";
      if (event.target.files.length == 0) {
        this.errorMessage = "No files uploaded";
        this.filesUploaded = 0;
        this.allowUpload = false;
        return;
      } else if (event.target.files.length == 1) {
        this.userCsv = event.target.files[0];
        this.filesUploaded = 1;
        this.allowUpload = true;
      } else if (event.target.files.length == 2) {
        this.filesUploaded = 2;
        let fileArray = Array.from(event.target.files);
        this.userCsv = fileArray.find(file => file.name == "users.csv");
        this.groupCsv = fileArray.find(file => file.name == "groups.csv");
        if (this.userCsv == undefined || this.groupCsv == undefined) {
          this.errorMessage = "The names of the files uploaded must be 'users.csv' and 'groups.csv'.";
          this.allowUpload = false;
          return;
        } else {
          this.allowUpload = true;
        }
      } else {
        this.errorMessage = "Please only upload between one or two files";
        this.allowUpload = false;
      }
    }
    submitUsers(event) {
      this.serverDestroyedLogs = false;
      this.serverReceivedUsers = false;
      if (this.filesUploaded == 0) {
        this.errorMessage = "No files uploaded";
        return;
      } else if (this.filesUploaded == 1 && this.allowUpload) {
        const reader = new FileReader();
        reader.onload = () => {
          const file = reader.result;
          this.getCsrfToken().then(async csrf => {
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('X-CSRF-Token', csrf);
            let jsonResponse = await this.postData('/user_migrate_upload.json', {
              users: file
            }, myHeaders);
            if (jsonResponse.status == 200) {
              this.serverReceivedUsers = true;
            }
          });
        };
        reader.readAsText(this.userCsv);
      } else if (this.filesUploaded == 2 && this.allowUpload) {
        //Convert from FileList to array
        const userreader = new FileReader();
        const groupreader = new FileReader();
        userreader.onload = () => {
          //Reusing userCsv variable may be bad design, but it does work 
          this.userCsv = userreader.result;
          groupreader.readAsText(this.groupCsv);
        };
        groupreader.onload = () => {
          this.groupCsv = groupreader.result;
          this.getCsrfToken().then(async csrf => {
            let myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append('X-CSRF-Token', csrf);
            let jsonResponse = await this.postData('/user_migrate_upload.json', {
              users: this.userCsv,
              groups: this.groupCsv
            }, myHeaders);
            if (jsonResponse.status == 200) {
              this.serverReceivedUsers = true;
            }
          });
        };
        userreader.readAsText(this.userCsv);
      } else {
        this.errorMessage = "Something went wrong. Check you have supplied only 1 or 2 files and if 2 are supplied they are named 'users.csv' and 'groups.csv'.";
        return;
      }
    }
    getCsrfToken() {
      return fetch('/session/csrf.json').then(res => res.json()).then(json => {
        return json.csrf;
      });
    }
    async postData() {
      let url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      let headers = arguments.length > 2 ? arguments[2] : undefined;
      return await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      }).then(res => {
        return res.json();
      }).then(json => {
        return json;
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userCsv", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "groupCsv", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userLogs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "serverReceivedUsers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "serverDestroyedLogs", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "filesUploaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "allowUpload", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getUserLogs", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getUserLogs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyLogs", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "destroyLogs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadUsersFile", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "uploadUsersFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitUsers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submitUsers"), _class.prototype)), _class);
});